import { Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LockMultipleCalls } from "../../../services/promotion.services";
import backgroundBottomDefault from "../../assets/images/contact-footer-default.png";
import backgroundDefault from "../../assets/images/product-detail-header-default.png";
import CustomizationGroup from "../../components/customization-group-component/customization-group.page";
import FnbCheckBox from "../../components/fnb-checkbox/fnb-checkbox";
import FnbFroalaEditor from "../../components/fnb-froala-editor";
import { FnbInput } from "../../components/fnb-input/fnb-input.component";
import { FnbSelectMultiple } from "../../components/fnb-select-multiple/fnb-select-multiple";
import SelectBackgroundComponent from "../../components/select-background/select-background.component";
import SelectColorGroupComponent from "../../components/select-color-group/select-color-group.component";
import PageType from "../../constants/page-type.constants";
import { backgroundTypeEnum } from "../../constants/store-web-page.constants";
import defaultConfig from "../../default-store.config";

function ContactCustomize(props) {
  const { form, updateFormValues, onChange, pageConfig, listBranch } = props;
  const [t] = useTranslation();
  const [pageContentContactEditor, setPageContentContactEditor] = useState("");
  const [pageContentInformationEditor, setPageContentInformationEditor] = useState("");
  const bestDisplay = "1920 x 569 px";
  const defaultThemePageConfig = defaultConfig?.pages?.find((p) => p.id === PageType.CONTACT_PAGE);
  const [allBranches, setAllBranches] = useState(pageConfig?.config?.information?.isAllBranches ?? true);
  const [selectedValues, setSelectedValues] = useState([]);
  const translateData = {
    title: t("storeWebPage.footerThemeConfiguration.title"),
    header: t("storeWebPage.header.title"),
    titleHeader: t("storeWebPage.footerThemeConfiguration.title"),
    haveQuestion: t("storeWebPage.contact.haveQuestion", "HAVE QUESTION?"),
    callUsOrVisitPlace: t("storeWebPage.contact.callUsOrVisitPlace", "CALL US OR VISIT PLACE"),
    description: t("storeWebPage.contact.description", "Description"),
    selectBranchesPlaceholder: t("contact.selectCategoryPlaceholder", "Select Branches"),
    allBranches: t("productCategory.branch.all"),
    selectBranchValidateMessage: t("productCategory.branch.selectBranchValidateMessage"),
    selectBranch: t("storeWebPage.contact.selectBranch"),
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (updateFormValues) {
          updateFormValues();
        }
        await getInitInformationData();
        await getInitContactData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setInitFormValue();
    fetchData();
  }, []);
  const setInitFormValue = async () => {
    LockMultipleCalls(async () => {
      setAllBranches(form.getFieldsValue()?.config?.information?.isAllBranches);
    }, "Lock_setInitFormValue");
  };

  const getInitInformationData = () => {
    const contentEditor = form?.getFieldsValue()?.config?.information?.description;
    setPageContentInformationEditor(contentEditor);
  };
  const getInitContactData = () => {
    const contentEditor = form?.getFieldsValue()?.config?.contactUs?.description;
    setPageContentContactEditor(contentEditor);
  };

  const pageData = {
    maxSizeUploadMb: 5,
    maxSizeUploadBackgroundImage: 20,
  };

  const onChangeBackgroundContact = (value, name) => {
    let changedValue = {
      key: [],
      value: null,
    };
    if (value === backgroundTypeEnum.IMAGE) {
      changedValue.key = ["config", name, "backgroundColor"];
    } else {
      changedValue.key = ["config", name, "backgroundImage"];
    }
    if (onChange) {
      onChange(changedValue);
    }
  };
  const onChangeOption = (e) => {
    const isChecked = e.target.checked;
    setSelectedValues([]);
    setAllBranches(isChecked);
  };

  return (
    <>
      <CustomizationGroup
        title={"Header"}
        defaultActiveKey={"0"}
        isNormal={true}
        content={
          <>
            <SelectBackgroundComponent
              {...props}
              formItemPreName={["config", "header"]}
              backgroundCustomize={pageConfig?.config?.header}
              onChangeBackgroundType={(value) => onChangeBackgroundContact(value, "header")}
              maxSizeUploadMb={20}
              defaultImage={backgroundDefault}
            />
            <SelectColorGroupComponent {...props} formItemPreName={["config", "header"]} />

            <div className="related-product-detail-title">
              <p>{translateData.title}</p>
              <Form.Item name={["config", "header", "title"]}>
                <FnbInput showCount allowClear placeholder="Contact" maxLength={255} />
              </Form.Item>
            </div>

            <div className="related-product-detail-title"></div>
          </>
        }
        clickToScroll={""}
        customizeKey={"theme2ElementCustomize.Blogs"}
      ></CustomizationGroup>

      <CustomizationGroup
        title={"Information components"}
        defaultActiveKey={"1"}
        content={
          <>
            <SelectBackgroundComponent
              {...props}
              formItemPreName={["config", "information"]}
              backgroundCustomize={pageConfig?.config?.information}
              bestDisplay={bestDisplay}
              isRequired={false}
              maxSizeUploadMb={pageData.maxSizeUploadBackgroundImage}
              defaultThemeColor={defaultThemePageConfig?.config?.information?.backgroundColor}
              onChangeBackgroundType={(value) => onChangeBackgroundContact(value, "information")}
            />
            <SelectColorGroupComponent {...props} formItemPreName={["config", "information"]} />

            <div className="related-product-detail-title">
              <p>{translateData.header}</p>
              <Form.Item name={["config", "information", "header"]}>
                <FnbInput showCount allowClear placeholder={translateData.haveQuestion} maxLength={255} />
              </Form.Item>
            </div>

            <div className="related-product-detail-title">
              <p>{translateData.title}</p>
              <Form.Item name={["config", "information", "title"]}>
                <FnbInput showCount allowClear placeholder={translateData.callUsOrVisitPlace} maxLength={255} />
              </Form.Item>
            </div>

            <div className="related-product-detail-title">
              <p>{translateData.description}</p>
              <Form.Item name={["config", "information", "description"]}>
                <FnbFroalaEditor
                  value={pageContentInformationEditor}
                  onChange={(value) => {
                    setPageContentInformationEditor(value);
                  }}
                />
              </Form.Item>
            </div>
            <>
              <div className="chose-select-branches">
                <Form.Item
                  name={["config", "information", "isAllBranches"]}
                  valuePropName="checked"
                  initialValue={allBranches}
                >
                  <FnbCheckBox onChange={(e) => onChangeOption(e)}>
                    <span className="related-product-detail-title"> {translateData.allBranches}</span>
                  </FnbCheckBox>
                </Form.Item>
              </div>
              <Row>
                <Col span={24} hidden={allBranches}>
                  <Form.Item
                    name={["config", "information", "branchIds"]}
                    rules={[
                      {
                        required: !allBranches,
                        message: translateData.selectBranchValidateMessage,
                      },
                    ]}
                  >
                    <FnbSelectMultiple
                      defaultValue={selectedValues}
                      placeholder={translateData.selectBranchesPlaceholder}
                      allowClear
                      option={listBranch?.map((item) => ({
                        id: item.id,
                        name: item.name,
                      }))}
                    ></FnbSelectMultiple>
                  </Form.Item>
                </Col>
                <Col span={24} hidden={!allBranches}>
                  <Form.Item>
                    <FnbSelectMultiple
                      value={translateData.selectBranch}
                      className="disable-branch-contact"
                      disabled={true}
                    ></FnbSelectMultiple>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </>
        }
      ></CustomizationGroup>

      <CustomizationGroup
        title={"Contact us components"}
        defaultActiveKey={"2"}
        content={
          <>
            <SelectBackgroundComponent
              {...props}
              formItemPreName={["config", "contactUs"]}
              backgroundCustomize={pageConfig?.config?.contactUs}
              onChangeBackgroundType={(value) => onChangeBackgroundContact(value, "contactUs")}
              maxSizeUploadMb={20}
              defaultImage={backgroundBottomDefault}
            />
            <SelectColorGroupComponent {...props} formItemPreName={["config", "contactUs"]} />

            <div className="related-product-detail-title">
              <p>{translateData.description}</p>
              <Form.Item name={["config", "contactUs", "description"]}>
                <FnbFroalaEditor
                  value={pageContentContactEditor}
                  onChange={(value) => setPageContentContactEditor(value)}
                />
              </Form.Item>
            </div>
          </>
        }
      ></CustomizationGroup>
    </>
  );
}

export default ContactCustomize;
